import { difference } from "@/utils/difference";
import { Dictionary } from "vue-router/types/router";
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import BankAccountApplicationsListRouteQuery
	from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListRouteQuery";
import BankAccountApplicationsListState from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListState";
import { parseBooleanParameter } from "@/utils/query";

export default class BankAccountApplicationsListRouteQueryService {
	initialRouteQuery: BankAccountApplicationsListRouteQuery;
	defaultRouteQuery: BankAccountApplicationsListRouteQuery;

	constructor(defaultRouteQuery: BankAccountApplicationsListRouteQuery) {
		this.initialRouteQuery = defaultRouteQuery;
		this.setDefaultRoute(defaultRouteQuery);
	}
	
	setDefaultRoute(defaultRouteQuery: BankAccountApplicationsListRouteQuery) {
		this.defaultRouteQuery = defaultRouteQuery;
	}

	resolveRouteQueryDictionary(state: BankAccountApplicationsListState) {
		let query = new BankAccountApplicationsListRouteQuery(
			state.filter.curatorsDepartmentEmployeeId,
			state.paging.page,
			state.filter.loanApplicationNumber,
			state.filter.counterpartyId,
			state.filter.status,
			state.filter.isAllCuratorsDepartmentEmployees
		);
		
		return difference(this.initialRouteQuery, query);
	}

	async resolveRouteQuery(query: Dictionary<any>) {
		try {
			let result = plainToInstance(BankAccountApplicationsListRouteQuery, {
				...this.defaultRouteQuery,
				...query,
				curatorsDepartmentEmployeeId: query.curatorsDepartmentEmployeeId ?? "",
				isAllEmployees: parseBooleanParameter(query.isAllEmployees, this.defaultRouteQuery.isAllCuratorsDepartmentEmployees)
			}, { enableImplicitConversion: true });

			await validateOrReject(result);

			return result;
		} catch (e) {
			console.error(e);
			return this.defaultRouteQuery;
		}
	}
}
