import { IsIn, IsInt, IsString, Max, Min, IsEnum, IsBoolean } from "class-validator";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";

export default class BankAccountApplicationsListRouteQuery {
	@IsString()
	curatorsDepartmentEmployeeId: string;
	
	@IsInt()
	@Min(0)
	@Max(1000)
	page: number;

	@IsString()
	loanApplicationNumber: string;

	@IsString()
	counterpartyId: string;

	@IsString()
	@IsIn([...Object.values(BankAccountApplicationStatusTypeEnum), ""])
	status: string;
	
	@IsBoolean()
	isAllCuratorsDepartmentEmployees: boolean;

	constructor(
		curatorsDepartmentEmployeeId: string = "",
		page: number = 1,
		loanApplicationNumber: string = "",
		counterpartyId: string = "",
		status: string = "",
		isAllCuratorsDepartmentEmployees: boolean = false
	)
	{
		this.curatorsDepartmentEmployeeId = curatorsDepartmentEmployeeId
		this.page = page;
		this.loanApplicationNumber = loanApplicationNumber;
		this.counterpartyId = counterpartyId;
		this.status = status;
		this.isAllCuratorsDepartmentEmployees = isAllCuratorsDepartmentEmployees;
	}
}
