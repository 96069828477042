import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import BankAccountApplicationsListState from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListState";

export interface ApiGetBankAccountApplicationsParameters {
	loanApplicationNumber: string;
	counterpartyId: string;
	status: BankAccountApplicationStatusTypeEnum | string;
	curatorsDepartmentEmployeeId: string;
	sourceType?: SourceTypeEnum | undefined;
	take: number;
	skip: number;
}

export class ApiGetBankAccountApplicationsParametersMapper {
	static map(source: BankAccountApplicationsListState): ApiGetBankAccountApplicationsParameters {
		return {
			loanApplicationNumber: source.filter.loanApplicationNumber,
			counterpartyId: source.filter.counterpartyId,
			status: source.filter.status,
			curatorsDepartmentEmployeeId: source.filter.curatorsDepartmentEmployeeId,
			take: source.paging.pageSize,
			skip: source.paging.pageSize * (source.paging.page - 1),
			sourceType: undefined
		};
	}
}

