import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import { ApiBankBranchInfoPersisted } from "@/api/bar/types/dictionaries/apiBankBranchInfoPersisted";
import ApiSignStatus from "@/api/bar/types/dictionaries/apiSignStatus";
import ApiBankAccountType from "@/api/bar/types/dictionaries/apiBankAccountType";
import ApiElectronicDocumentStatus from "@/api/bar/types/dictionaries/apiElectronicDocumentStatus";
import ApiApplicationStatus from "@/api/bar/types/dictionaries/apiApplicationStatus";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { ApiCurrency } from "@/api/bar/types/dictionaries/apiCurrency";
import { ApiBankResponsibleUser } from "@/api/bar/types/dictionaries/apiBankResponsibleUser";
import { ApiStaffEmployee } from "@/api/bar/types/apiStaffEmployee";
import { ApiSuggestedProject } from "@/api/bar/types/apiSuggestedProject";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiSuggestedCounterparty } from "@/api/bar/types/apiSuggestedCounterparty";
import { SuggestedCounterpartiesParameterTypeEnum } from "@/store/bar/types/SuggestedCounterpartiesParameterTypeEnum";
import ApiMonitoringSystemConnectionType from "@/api/bar/types/dictionaries/apiMonitoringSystemConnectionType";
import { ApiAgreement } from "@/api/bar/types/agreement/apiAgreement";
import ApiPaperDocumentStatus from "@/api/bar/types/dictionaries/apiPaperDocumentStatus";
import ApiApplicationBankAccountUsageType from "@/api/bar/types/dictionaries/apiApplicationBankAccountUsageType";
import ApiSigners from "@/api/bar/types/dictionaries/apiSigners";
import { ApiBankSettings } from "@/api/bar/types/apiBankSettings";
import { ApiCuratorsDepartmentManager } from "@/api/bar/types/apiCuratorsDepartmentManager";

export class DictionariesController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getSignStatuses = async (): Promise<ApiSignStatus[]> => {
		let data = await this.client.get<ApiSignStatus[]>(urls.bar.dictionaries.getSignStatuses);
		return plainToInstance(ApiSignStatus, data);
	};
	
	getBankAccountTypes = async (): Promise<ApiBankAccountType[]> => {
		let data = await this.client.get<ApiBankAccountType[]>(urls.bar.dictionaries.getBankAccountTypes);
		return plainToInstance(ApiBankAccountType, data);
	};
	
	getApplicationBankAccountUsageTypes = async (): Promise<ApiApplicationBankAccountUsageType[]> => {
		let data = await this.client.get<ApiApplicationBankAccountUsageType[]>(urls.bar.dictionaries.getApplicationBankAccountUsageTypes);
		return plainToInstance(ApiApplicationBankAccountUsageType, data);
	};
	
	getElectronicDocumentStatuses = async (): Promise<ApiElectronicDocumentStatus[]> => {
		let data = await this.client.get<ApiElectronicDocumentStatus[]>(urls.bar.dictionaries.getElectronicDocumentStatuses);
		return plainToInstance(ApiElectronicDocumentStatus, data);
	};
	
	getPaperDocumentStatuses = async (): Promise<ApiPaperDocumentStatus[]> => {
		let data = await this.client.get<ApiPaperDocumentStatus[]>(urls.bar.dictionaries.getPaperDocumentStatuses);
		return plainToInstance(ApiPaperDocumentStatus, data);
	};
	
	getApplicationStatuses = async (): Promise<ApiApplicationStatus[]> => {
		let data = await this.client.get<ApiApplicationStatus[]>(urls.bar.dictionaries.getApplicationStatuses);
		return plainToInstance(ApiApplicationStatus, data);
	};
	
	getMonitoringSystemConnectionTypes = async (): Promise<ApiMonitoringSystemConnectionType[]> => {
		let data = await this.client.get<ApiMonitoringSystemConnectionType[]>(urls.bar.dictionaries.getMonitoringSystemConnectionTypes);
		return plainToInstance(ApiMonitoringSystemConnectionType, data);
	};
	
	getBanks = async (): Promise<ApiBankInfoPersisted[]> => {
		return await this.client.get<ApiBankInfoPersisted[]>(urls.bar.dictionaries.getBanks);
	};
	
	getBank = async (): Promise<ApiBankInfoPersisted> => {
		return await this.client.get<ApiBankInfoPersisted>(urls.bar.dictionaries.getBank);
	};
	
	getBankBranches = async (bankId: string): Promise<ApiBankBranchInfoPersisted[]> => {
		const url = urls.bar.dictionaries.getBankBranches.replace(urlTemplateParts.id, bankId);
		return await this.client.get<ApiBankBranchInfoPersisted[]>(url);
	};
	
	getBankBranch = async (bankId: string, branchId: string): Promise<ApiBankBranchInfoPersisted> => {
		const url = urls.bar.dictionaries.getBankBranch.replace(urlTemplateParts.id, bankId).replace(urlTemplateParts.subId, branchId);
		return await this.client.get<ApiBankBranchInfoPersisted>(url);
	};
	
	getBankResponsibleUsers = async (): Promise<ApiBankResponsibleUser[]> => {
		return await this.client.get<ApiBankResponsibleUser[]>(urls.bar.dictionaries.getBankResponsibleUsers);
	};
	
	getBankAndBankBranchByBik = async (bik: string): Promise<ApiBankInfoPersisted> => {
		const url = urls.bar.dictionaries.getBankAndBankBranchByBik.replace(urlTemplateParts.id, bik);
		
		return await this.client.get<ApiBankInfoPersisted>(url);
	};
	
	getSuggestedProjects = async (query: string, olkCounterpartyId?: string): Promise<ApiSuggestedProject[]> => {
		const url = prepareUrl(urls.bar.dictionaries.getSuggestedProjects, { query, olkCounterpartyId });
		return await this.client.get<ApiSuggestedProject[]>(url);
	};
	
	getSuggestedCounterparties = async (parameter: string, parameterType: SuggestedCounterpartiesParameterTypeEnum): Promise<ApiSuggestedCounterparty[]> => {
		let url = "";
		switch (parameterType) {
			case SuggestedCounterpartiesParameterTypeEnum.QUERY:
				url = prepareUrl(urls.bar.dictionaries.getSuggestedCounterpartiesByQuery, { query: parameter });
				break;
			case SuggestedCounterpartiesParameterTypeEnum.ID:
				url = urls.bar.dictionaries.getSuggestedCounterpartiesById.replace(urlTemplateParts.id, parameter);
				break;
		}
		return await this.client.get<ApiSuggestedCounterparty[]>(url);
	};
	
	getAgreementInfo = async (loanApplicationNumber: string): Promise<ApiAgreement> => {
		return await this.client.get<ApiAgreement>(urls.bar.dictionaries.getAgreementInfo.replace(urlTemplateParts.id, loanApplicationNumber));
	};
	
	getCurrencies = async (): Promise<ApiCurrency[]> => {
		return await this.client.get<ApiCurrency[]>(urls.bar.dictionaries.getCurrencies);
	};
	
	getStaffEmployees = async (): Promise<ApiStaffEmployee[]> => {
		return await this.client.get<ApiStaffEmployee[]>(urls.bar.dictionaries.getStaffEmployees);
	};
	
	getSigners = async (): Promise<ApiSigners[]> => {
		const data = await this.client.get<ApiSigners[]>(urls.bar.dictionaries.getSigners);
		
		return plainToInstance(ApiSigners, data);
	};
	
	getBankSettings = async (): Promise<ApiBankSettings> => {
		return await this.client.get<ApiBankSettings>(urls.bar.dictionaries.getBankSettings);
	};
	
	getCuratorsDepartmentManagers = async (): Promise<ApiCuratorsDepartmentManager[]> => {
		return await this.client.get<ApiCuratorsDepartmentManager[]>(urls.bar.dictionaries.getCuratorsDepartmentManagers);
	};
}
