import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/bar/modules/bankAccountApplicationsList/types";
import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, GetterTree, MutationPayload, MutationTree, Store } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/bar/dictionaries";
import BankAccountApplicationsListState from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListState";
import BatchService from "@/services/batchService";
import RouteMixinBuilder from "@/store/shared/route";
import ListingMixinBuilder from "@/store/shared/listing";
import SortingMixinBuilder from "@/store/shared/sorting";
import SearchMixinBuilder from "@/store/shared/search";
import BankAccountApplicationsListRouteQueryService
	from "@/store/bar/modules/bankAccountApplicationsList/services/bankAccountApplicationsListRouteQueryService";
import BankAccountApplicationsListRouteQuery
	from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListRouteQuery";
import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import SubscribersManager from "@/store/manager/subscribersManager";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import routeTypes from "@/store/shared/route/types";
import router from "@/router/bar";
import { RouteNames } from "@/router/bar/routes";
import PagingMixinBuilder from "@/store/shared/paging";
import {
	ApiGetBankAccountApplicationsParameters,
	ApiGetBankAccountApplicationsParametersMapper
} from "@/api/bar/types/apiGetBankAccountApplicationsParameters";
import { SuggestedCounterpartiesParameterTypeEnum } from "@/store/bar/types/SuggestedCounterpartiesParameterTypeEnum";
import { BankInfoMapper } from "@/store/bar/types/bankInfo";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import {
	BankAccountApplicationsListItem,
	BankAccountApplicationsListItemMapper
} from "@/store/bar/modules/bankAccountApplicationsList/types/bankAccountApplicationsListItem";
import UserState from "@/store/bar/modules/user/types/userState";
import storeManager from "@/store/manager";
import userTypes from "@/store/bar/modules/user/types";
import { BarRolesTypeEnum } from "@/store/bar/modules/user/types/BarRolesTypeEnum";
import { ApiBarUser } from "@/api/bar/types/apiBarUser";
import { cloneDeep } from "lodash";

const abortService = new AbortService();
const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);

const defaultRouteQuery = new BankAccountApplicationsListRouteQuery();
const routeQueryService = new BankAccountApplicationsListRouteQueryService(defaultRouteQuery);
const updateListingBatchService = new BatchService(({ interval: 100 }));
const routeMixin = (new RouteMixinBuilder<BankAccountApplicationsListState>()).build();

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountApplicationsListState(
			new ListingModel<BankAccountApplicationsListItem>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			}),
			routeMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

let subscribersManager: SubscribersManager<BankAccountApplicationsListState>;

let unsubscribeCallback = () => {
};
let store: Store<{}>;

const initializeSubscribersManager = (value: Store<{}>) => {
	store = value;
	subscribersManager = new SubscribersManager<BankAccountApplicationsListState>(store);
};

const subscribe = async (mutation: MutationPayload, rootState: any) => {
	let state = resolveNestedState<BankAccountApplicationsListState>(rootState, namespace);
	switch (mutation.type) {
		case resolveMutation(routeTypes.namespace, routeTypes.mutationTypes.ROUTE_CHANGED):
			if((mutation.payload.from.name === mutation.payload.to.name) && !state.route.isPushing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.processRouteQuery));
			break;
		case resolveMutation(namespace, mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_COUNTERPARTY_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_STATUS):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID):
		case resolveMutation(namespace, mutationTypes.SET_FILTER_IS_ALL_CURATORS_DEPARTMENT_EMPLOYEES):
		case resolveMutation(namespace, mutationTypes.SET_FILTER):
		{
			if(!state.route.isProcessing) {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.resetPagingPage));
			}
			
			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});
			
			break;
		}
		case resolveMutation(namespace, mutationTypes.SET_PAGING_PAGE):
		{
			if(!state.route.isProcessing)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.pushRoute));

			updateListingBatchService.push(async () => {
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.updateListingItems));
			});

			break;
		}
		case resolveMutation(userTypes.namespace, userTypes.mutationTypes.SET_USER):
		{
			const user = mutation.payload as ApiBarUser;
			const isCuratorsDepartmentManager = user.roles.includes(BarRolesTypeEnum.CURATORS_DEPARTMENT_MANAGER);
			
			if(isCuratorsDepartmentManager)
				await subscribersManager.dispatch(resolveAction(namespace, actionTypes.setCuratorsDepartmentDefaultFilter));
			
			break;
		}
	}
};


const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountApplicationsListState, any>>{
	...listingMixin.getters,
	[getterTypes.currentUser]: (state, getters, rootState) => {
		return resolveNestedState<UserState>(rootState, storeManager.bar.barUser.namespace).user;
	},
	[getterTypes.isCuratorsDepartmentManagersIncludesCurrentUser]: (state, getters, rootState) => {
		const user = resolveNestedState<UserState>(rootState, storeManager.bar.barUser.namespace).user;
		
		return state.curatorsDepartmentManagers.some(x => x.id === user.employeeId);
	}
};

const actions = <ActionTree<BankAccountApplicationsListState, any>>{
	...listingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	...pagingMixin.actions,
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...routeMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.processRouteQuery);
		await dispatch(actionTypes.reconstituteRoute);
		
		await Promise.all([
			dispatch(actionTypes.updateListingItems),
			dispatch(actionTypes.fetchDictionaries)
		]);
		
		unsubscribeCallback = subscribersManager.subscribe(subscribe);
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		
		dispatch(actionTypes.setCuratorsDepartmentDefaultFilter);
	},
	async [actionTypes.fetchDictionaries]({ commit, state }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			
			const [
				banks,
				applicationStatuses,
				bankAccountTypes,
				curatorsDepartmentManagers
			] = await Promise.all([
				dictionariesController.getBanks(),
				dictionariesController.getApplicationStatuses(),
				dictionariesController.getBankAccountTypes(),
				dictionariesController.getCuratorsDepartmentManagers()
			]);
			
			commit(mutationTypes.SET_BANKS, banks.map((x: ApiBankInfoPersisted) => BankInfoMapper.map(x)));
			commit(mutationTypes.SET_APPLICATION_STATUSES, applicationStatuses);
			commit(mutationTypes.SET_BANK_ACCOUNT_TYPES, bankAccountTypes);
			commit(mutationTypes.SET_CURATORS_DEPARTMENT_MANAGERS, curatorsDepartmentManagers);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.fetchSuggestedCounterparties]({ commit },
		{ parameter, type }: { parameter: string, type: SuggestedCounterpartiesParameterTypeEnum })
	{
		commit(mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING, true);
		
		try {
			const suggestedCounterparties = await dictionariesController.getSuggestedCounterparties(parameter, type);
			
			if(type === SuggestedCounterpartiesParameterTypeEnum.QUERY)
				commit(mutationTypes.SET_SUGGESTED_COUNTERPARTIES, suggestedCounterparties);
			
			if(type === SuggestedCounterpartiesParameterTypeEnum.ID)
				commit(mutationTypes.SET_SUGGESTED_COUNTERPARTIES, [suggestedCounterparties]);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING, false);
		}
	},
	async [actionTypes.updateListingItems]({ commit, state }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			const payload: ApiGetBankAccountApplicationsParameters = ApiGetBankAccountApplicationsParametersMapper.map(state);
			
			const { items, totalCount } = await barController.getBankAccountApplicationsItems(payload);
			
			commit(mutationTypes.SET_LISTING_ITEMS, items.map((x: ApiBankAccountApplicationInfoPersisted) => BankAccountApplicationsListItemMapper.map(x)));
			commit(mutationTypes.SET_PAGING_TOTAL, totalCount);
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			console.error(error);
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.setCuratorsDepartmentDefaultFilter]({ commit, getters, rootGetters, state }) {
		const currentUser = getters[getterTypes.currentUser] as ApiBarUser;
		
		if(!state.isInitialized || !currentUser?.employeeId || !state.curatorsDepartmentManagers.some(x => x.id === currentUser.employeeId))
			return;
		
		const isBarUserCuratorsDepartmentManager = rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
			userTypes.getterTypes.isBarUserCuratorsDepartmentManager)];
		
		if(isBarUserCuratorsDepartmentManager) {
			const defaultRouteQuery = new BankAccountApplicationsListRouteQuery(currentUser.employeeId);
			
			if(!state.filter.isAllCuratorsDepartmentEmployees && !state.filter.curatorsDepartmentEmployeeId)
				commit(mutationTypes.SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID, currentUser.employeeId);
			
			routeQueryService.setDefaultRoute(defaultRouteQuery);
		}
	},
	async [actionTypes.processRouteQuery]({ rootState, dispatch, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, true);
		
		let routeQuery = await routeQueryService.resolveRouteQuery(rootState.route.query);
		
		commit(mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER, routeQuery.loanApplicationNumber);
		commit(mutationTypes.SET_FILTER_COUNTERPARTY_ID, routeQuery.counterpartyId);
		commit(mutationTypes.SET_FILTER_STATUS, routeQuery.status);
		commit(mutationTypes.SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID, routeQuery.curatorsDepartmentEmployeeId);
		commit(mutationTypes.SET_FILTER_IS_ALL_CURATORS_DEPARTMENT_EMPLOYEES, routeQuery.isAllCuratorsDepartmentEmployees);
		
		if(routeQuery.counterpartyId)
			await dispatch(actionTypes.fetchSuggestedCounterparties,
				{ parameter: routeQuery.counterpartyId, type: SuggestedCounterpartiesParameterTypeEnum.ID });
		
		commit(mutationTypes.SET_IS_ROUTE_PROCESSING, false);
	},
	async [actionTypes.pushRoute]({ rootState, state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.push({
			name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.reconstituteRoute]({ rootState, state, commit }) {
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, true);
		
		await router.replace({
			name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST,
			params: router.currentRoute.params,
			query: routeQueryService.resolveRouteQueryDictionary(state)
		}).catch(() => {
		});
		
		commit(mutationTypes.SET_IS_ROUTE_PUSHING, false);
	},
	async [actionTypes.destroy]({ dispatch }) {
		unsubscribeCallback();
		await dispatch(actionTypes.destroyBase);
	}
};

const mutations = <MutationTree<BankAccountApplicationsListState>>{
	...listingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	...pagingMixin.mutations,
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...routeMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_BANKS](state, value) {
		state.banks = value;
	},
	[mutationTypes.SET_APPLICATION_STATUSES](state, value) {
		state.applicationStatuses = value;
	},
	[mutationTypes.SET_BANK_ACCOUNT_TYPES](state, value) {
		state.bankAccountTypes = value;
	},
	[mutationTypes.SET_FILTER_LOAN_APPLICATION_NUMBER](state, value) {
		state.filter.loanApplicationNumber = value;
	},
	[mutationTypes.SET_FILTER_COUNTERPARTY_ID](state, value) {
		state.filter.counterpartyId = value;
	},
	[mutationTypes.SET_FILTER_STATUS](state, value) {
		state.filter.status = value;
	},
	[mutationTypes.SET_FILTER_CURATORS_DEPARTMENT_EMPLOYEE_ID](state, value) {
		state.filter.curatorsDepartmentEmployeeId = value;
	},
	[mutationTypes.SET_FILTER_IS_ALL_CURATORS_DEPARTMENT_EMPLOYEES](state, value) {
		state.filter.isAllCuratorsDepartmentEmployees = value;
	},
	[mutationTypes.SET_FILTER](state, value) {
		state.filter = cloneDeep(value);
	},
	[mutationTypes.SET_IS_FETCH_SUGGESTED_COUNTERPARTIES_LOADING](state, value) {
		state.isFetchSuggestedCounterpartyLoading = value;
	},
	[mutationTypes.RESET_SUGGESTED_COUNTERPARTIES](state, value) {
		state.suggestedCounterparties = [];
	},
	[mutationTypes.SET_SUGGESTED_COUNTERPARTIES](state, value) {
		state.suggestedCounterparties = value;
	},
	[mutationTypes.SET_CURATORS_DEPARTMENT_MANAGERS](state, value) {
		state.curatorsDepartmentManagers = value;
	}
};

export {
	namespace, state, getters, actions, mutations, initializeSubscribersManager
};

const bankAccountApplicationsListModule = {
	namespace, state, getters, actions, mutations, initializeSubscribersManager, namespaced: true
};

export default bankAccountApplicationsListModule;
