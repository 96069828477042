import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { IsBoolean } from "class-validator";

export default class BankAccountApplicationsListFilter {
	curatorsDepartmentEmployeeId: string;
	
	@IsBoolean()
	isAllCuratorsDepartmentEmployees: boolean;
	
	loanApplicationNumber: string;
	counterpartyId: string;
	status: BankAccountApplicationStatusTypeEnum | string;

	constructor(
		curatorsDepartmentEmployeeId: string = "",
		isAllCuratorsDepartmentEmployees: boolean = false,
		loanApplicationNumber: string = "",
		counterpartyId: string = "",
		status: BankAccountApplicationStatusTypeEnum | string = ""
	)
	{
		this.curatorsDepartmentEmployeeId = curatorsDepartmentEmployeeId
		this.isAllCuratorsDepartmentEmployees = isAllCuratorsDepartmentEmployees;
		this.loanApplicationNumber = loanApplicationNumber;
		this.counterpartyId = counterpartyId;
		this.status = status;
	}
}
